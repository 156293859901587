<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-link
          to="/dashboard"
          :name="$t('sidebar.dashboard')"
          icon="tim-icons icon-chart-pie-36"
        />
        <sidebar-link
          to="/icons"
          :name="$t('sidebar.customers')"
          icon="tim-icons icon-satisfied"
        />
        <sidebar-link
          to="/maps"
          :name="$t('sidebar.boxes')"
          icon="tim-icons icon-app"
        />
        <sidebar-link
          to="/notifications"
          :name="$t('sidebar.pentests')"
          icon="tim-icons icon-zoom-split"
        />
        <sidebar-link
          to="/profile"
          :name="$t('sidebar.reports')"
          icon="tim-icons icon-paper"
        />
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss"></style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
