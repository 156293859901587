<template>
  <footer class="footer">
    <div class="container-fluid">
      <ul class="nav">
        <li class="nav-item">
          <a class="nav-link" href="https://www.defenderbox.de">
            DEFENDERBOX
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://defenderbox.de/#news">
            BLOG
          </a>
        </li>
      </ul>
      <div class="copyright">
        © {{ year }} made with <i class="tim-icons icon-controller"></i> by
        <a href="https://www.defenderbox.de" target="_blank" rel="noopener"
          >DEFENDERBOX</a>
        .
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
